import React from 'react'
import Layout from '../layouts/index'
import Helmet from 'react-helmet'

const SupportPage = (props) => {
  return (
    <Layout>
      <Helmet title={'Support | Suggested - Build better products'}></Helmet>
      <div className="page">
        <h1>Support</h1>

        <p>
          If you have any questions, or need to get in touch with us, you can
          email us: <a href="mailto:support@Suggested.co">support@Suggested.co</a>
        </p>
        <p>
          Our team is based in London on GMT time. We will get back to you as
          soon as possible
        </p>
      </div>
    </Layout>
  )
}

export default SupportPage
